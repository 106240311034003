let AdminPageContentInstance = (function () {
    let instance;

    function createInstance() {
        let object = new AdminPageContentManager();
        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }

            return instance;
        }
    };
})();

function AdminPageContentManager() {
    this.colWrapperClass = '.meeb__moset-col-wrapper';
    this.bodyOverlayClass = 'page-overlay';
    this.bodyHideLinksClass = 'meeb__admin-hide-links';
}

AdminPageContentManager.prototype.toggleOverlay = function() {
    const visible = $('body').hasClass(this.bodyOverlayClass);
    if (visible) {
        this.hideOverlay();
    } else {
        this.showOverlay();
    }
}

AdminPageContentManager.prototype.showOverlay = function() {
    const body = $('body');
    body.addClass(this.bodyOverlayClass);
    body.addClass(this.bodyHideLinksClass);

    this.initHandles();
}

AdminPageContentManager.prototype.hideOverlay = function () {
    const body = $('body');
    body.removeClass(this.bodyOverlayClass);
    body.removeClass(this.bodyHideLinksClass);

    $(document).off('.page-content-handles');
}

AdminPageContentManager.prototype.initHandles = function() {
    $(document).off('.page-content-handles');
    $(document).on('click.page-content-handles', this.colWrapperClass, function (e) {
        e.preventDefault();
        e.stopPropagation();

        const link = $(this).data('edit-link');
        //MeebsterIframeInstance.getInstance().show(link);
        window.open(link);
    });
}