"use strict";

function GlobalUpload(url) {
    this.url = url;
    this.overlay = new MeebGalleryOverlay(false);
    this.succesDelay = 2000;

    console.log('global upload running');

    var self = this;
    this.overlay.onOverlayShow(function () {
        console.log('overlay show');
        $(document).on('dragover.globalUpload', function(e) {
            e.preventDefault();
            e.stopPropagation();

            return false;
        });

        $(document).on('drop.globalUpload', function (e) {
            if (!self.overlay.containsFiles(e)) {
                return true;
            }

            try {
                self.sendFiles(e);
            } catch(exception) {
                console.error(exception);
            }

            e.preventDefault();
            e.stopPropagation();


            return false;
        });
    });
};

GlobalUpload.prototype.sendFiles = function(e) {
    e.dataTransfer = e.originalEvent.dataTransfer;

    var dt = e.dataTransfer;
    var files = dt.files;

    var data = new FormData();
    data.append('upload_file', true);

    for (var i = 0; i < files.length; i++) {
        var file = files.item(i);
        data.append('files[]', file, file.name);
    }

    this.changeOverlayStep(1);

    var self = this;
    $.ajax({
        type: "POST",
        url: this.url,
        xhr: function() {
            var myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){
                myXhr.upload.addEventListener('progress', self.progressHandling, false);
            }
            return myXhr;
        },
        success: function (data) {
            self.changeOverlayStep(2);
            setTimeout(function() {
                updateSnippets(data);
                self.overlay.fadeOut();
                self.showGallery();
            }, self.succesDelay);
        },
        error: function (data) {
            updateSnippets(data);
            self.overlay.fadeOut();
        },
        async: true,
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000
    });
};

GlobalUpload.prototype.showGallery = function() {
    var galleryButton = $('.gallery-button');
    if (galleryButton.length > 0) {
        galleryButton.click();
    } else {
        var inputGallery = $('input[data-gallery-modal]:first');
        if (inputGallery.length > 0) {
            inputGallery.click();
        }
    }
};

GlobalUpload.prototype.progressHandling = function (event) {
    console.log('progress handling');
    /*var percent = 0;
    var position = event.loaded || event.position;
    var total = event.total;
    var progress_bar_id = "#progress-wrp";
    if (event.lengthComputable) {
        percent = Math.ceil(position / total * 100);
    }
    // update progressbars classes so it fits your code
    $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
    $(progress_bar_id + " .status").text(percent + "%");
    */
};

GlobalUpload.prototype.changeOverlayStep = function(step) {
    var target = this.overlay.overlay;

    if (step > 0) {
        var prev = step - 1;
        target.find('.step_' + prev).fadeOut('fast', function() {
            target.find('.step_' + step).fadeIn('fast');
        });
    } else {
        target.find('.step_' + step).fadeIn('fast');
    }
};

GlobalUpload.prototype.destroy = function() {
    $(document).off('.globalUpload');
    if (this.overlay) {
        this.overlay.destroy();
    }
}
