function VatFieldsManager(options)
{
    this.idElement = options.idElement;
    this.vatIdElement = options.vatIdElement;
    this.link = options.link;
    this.legacyBootstrap = options.legacyBootstrap;

    this.countryElement = this.parseOption(options, 'countryElement');
    this.companyNameElement = this.parseOption(options, 'companyNameElement');
    this.streetElement = this.parseOption(options, 'streetElement');
    this.cityElement = this.parseOption(options, 'cityElement');
    this.pscElement = this.parseOption(options, 'pscElement');

    this.initialize();
}

VatFieldsManager.prototype.parseOption = function(options, optionName) {
    return typeof options[optionName] !== 'undefined' ? options[optionName] : false;
};

VatFieldsManager.prototype.hasAddressFields = function() {
    return this.countryElement || this.companyNameElement || this.streetElement || this.cityElement || this.pscElement;
};

VatFieldsManager.prototype.initialize = function() {
    this.idElement = this.createNewFields(this.idElement);
    this.vatIdElement = this.createNewFields(this.vatIdElement);

    const self = this;
    this.idElement.find('input').on('keyup.vatfield', function () {
        self.checkIdField($(this));
    });

    let requestInfo = { request: false };
    this.vatIdElement.find('input').on('keyup.vatfield', function () {
        self.checkVatIdField($(this), requestInfo);
    });

    if (this.idElement.length > 0 && this.idElement.find('input').val().length > 0) {
        this.checkIdField(this.idElement.find('input'));
    }
    if (this.vatIdElement.length > 0 && this.vatIdElement.find('input').val().length > 0) {
        this.checkVatIdField(this.vatIdElement.find('input'), requestInfo);
    }
};

VatFieldsManager.prototype.checkIdField = function($elem) {
    var val = $elem.val();
    var self = this;
    if (checkCompanyIdentificationNumber(val)) {
        self.showInputAppend($elem, 'fa-check', 'success', 'Toto IČ je validní');
    } else {
        self.showInputAppend($elem, 'fa-exclamation-triangle', 'warning', 'Pozor, toto IČ nemusí být správné');
    }
};

VatFieldsManager.prototype.checkVatIdField = function($elem, requestInfo) {
    var val = $elem.val();
    var elem = $elem;
    var self = this;

    self.showInputAppend($elem, 'fa-spinner faa-spin animated circle-wrapper', '', 'Načítám informace o DIČ');
    if (requestInfo.request !== false) {
        requestInfo.request.abort();
        requestInfo.request = false;
    }

    elem.parent().find('.status-addon').off('.vatsuccess');
    requestInfo.request = $.post(self.link, { vat: val }).done(function (data) {
        requestInfo.request = false;
        if (data.success) {
            if (self.hasAddressFields()) {
                self.showInputAppend(elem, 'fa-check', 'success cursor', 'Toto DIČ je platné, klikněte pro načtení firemních údajů z registru VIES');
                elem.parent().find('.status-addon').on('click.vatsuccess', function () {
                    console.log('data click');
                    if (self.companyNameElement)
                        self.companyNameElement.val(data.name);
                    if (self.streetElement)
                        self.streetElement.val(data.street);
                    if (self.cityElement)
                        self.cityElement.val(data.city);
                    if (self.pscElement)
                        self.pscElement.val(data.psc);
                    if (self.countryElement) {
                        self.countryElement.val(data.country);
                        self.countryElement.trigger('change');
                    }
                });
            } else {
                self.showInputAppend(elem, 'fa-check', 'success', 'Toto DIČ je platné');
            }
        } else {
            var type = data.reason == 'invalid' ? 'error' : 'warning';
            var title = data.reason == 'invalid' ? 'Pozor, toto DIČ nemusí být platné, systém nenašel firmu v registru VIES' : 'Systém se nemohl připojit k registru VIES';

            self.showInputAppend(elem, 'fa-exclamation-triangle', type, title);
        }
    });
};

VatFieldsManager.prototype.showInputAppend = function(element, icon, type, title) {
    var parent = element.closest('.input-group');
    parent.removeClass('success');
    parent.removeClass('warning');
    parent.removeClass('error');
    parent.removeClass('cursor');

    parent.addClass(type);

    var icon = $('<i class="fa ' + icon + '"></i>');
    var append = parent.find('.status-addon');
    append.attr('title', title);
    append.tooltip();
    append.tooltip('fixTitle');
    append.show();

    append.html(icon);
};

VatFieldsManager.prototype.destroy = function () {
    this.idElement.off('.vatfield');
    this.vatIdElement.off('.vatfield');
};

VatFieldsManager.prototype.createNewFields = function(element) {
    if (this.legacyBootstrap) {
        var fieldgroup = $('<div class="input-group m-b" style="width: 100%">\n' +
                                element.outerHTML() +
            '                  <span class="input-group-addon status-addon" style="width: 40px;">\n' +
            '                     <i class="fa"></i>\n' +
            '                  </span>\n' +
            '               </div>');
    } else {
        var fieldgroup = $('<div class="input-group">\n' +
            element.outerHTML() +
            '    <div class="input-group-append" style="width: 50px;">\n' +
            '      <div class="input-group-text status-addon" style="display: block; width: 50px">@</div>\n' +
            '    </div>\n' +
            '  </div>');
    }

    var parent = element.parent();
    parent.html('');
    fieldgroup.appendTo(parent);

    return fieldgroup;
};