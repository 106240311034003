let MosetInstance = (function () {
    let instance;

    function createInstance(di) {
        let object = new Moset(di);
        return object;
    }

    return {
        getInstance: function (di) {
            if (!instance) {
                instance = createInstance(di);
            }

            return instance;
        }
    };
})();

function Moset(di) {
    this.di = di;

    this.openId = "#meeb__overlay-trigger-show";
    this.closeId = "#meeb__overlay-trigger-close";
    this.dragId = "#meeb__admin-toolbar-drag-drop";
    this.controlId = "#meeb__admin-toolbar-main";
    this.pageId = "#meeb__overlay-trigger-show";
    this.collapsedId = '#meeb__admin-toolbar-collapsed';
    this.translationsId = '#meeb__translations-trigger-show';
    this.translationsOverlayId = '#meeb__admin_overlay';
    this.translationsLinkId = '#translations-links';
    this.translationsBodyClass = 'overlay-show';

    this.snappedClass = 'meeb__admin-toolbar-snapped';
    this.pageManager = AdminPageContentInstance.getInstance();

    this.mouseX = 0;
    this.mouseY = 0;

    this.elementX = 0;
    this.elementY = 0;

    this.restorePosition();
    this.restoreState();

    let self = this;

    /* TODO linky se otevrou a zavrou by default
    $(this.openId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.open($(this));
    });
    */

    $(this.pageId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.pageManager.toggleOverlay();
        $(this).toggleClass('active');
    });

    $(this.translationsId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.toggleTranslations($(this));
    });

    $(this.closeId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.hideTranslations($(this.translationsId)).then(function() {
            self.pageManager.hideOverlay();
            $(self.pageId).removeClass('active');

            self.hideLinks();
            self.savePosition();
            self.collapse();
        });
    });

    $(this.collapsedId).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        let body = $('body');
        body.removeClass(self.snappedClass);
        let cls = body.attr('class');

        let offset = 15;
        let x = self.elementX;
        let y = self.elementY;

        if (cls.indexOf('top-left') !== -1) {
            x += offset;
            y += offset;
        } else if (cls.indexOf('top-right') !== -1) {
            x -= offset;
            y += offset;
        } else if (cls.indexOf('bottom-left') !== -1) {
            x += offset;
            y -= offset;
        } else if (cls.indexOf('bottom-right') !== 1) {
            x -= offset;
            y -= offset;
        }

        self.changePosition(x, y);
        self.saveState(false);
        self.openLinks();
    });

    this.initDragging();
}

Moset.prototype.toggleTranslations = function(elem) {
    const target = $(this.translationsOverlayId);
    if (target.hasClass('show')) {
        this.hideTranslations(elem);
    } else {
        this.openTranslations();
    }
}

Moset.prototype.openTranslations = function() {
    $(this.translationsOverlayId).addClass('show');
    $('body').addClass(this.translationsBodyClass);
}

Moset.prototype.hideTranslations = function(elem) {
    const self = this;
    return new Promise(function (resolve, reject) {
        if (!$(self.translationsOverlayId).hasClass('show')) {
            $(self.translationsOverlayId).removeClass('show');
            $('body').removeClass(self.translationsBodyClass);

            resolve();
            return;
        }

        const link = $(self.translationsOverlayId).data('close-link');
        $(self.controlId).find('.nav-link').addClass('disabled');

        self.di.getService('page').open(link).then(function() {
            $(self.controlId).find('.nav-link').removeClass('disabled');

            $(self.translationsOverlayId).removeClass('show');
            $('body').removeClass(self.translationsBodyClass);
            resolve();
        });
    });
}

Moset.prototype.openLinks = function() {
    $('body').addClass('meeb__admin-show-links');
}

Moset.prototype.hideLinks = function() {
    $('body').removeClass('meeb__admin-show-links');
}

Moset.prototype.initDragging = function() {
    let self = this;

    $(this.dragId).mousedown(function(e) {
        e.preventDefault();
        e.stopPropagation();

        self.mouseX = e.clientX;
        self.mouseY = e.clientY;

        let position = $(self.controlId).position();
        self.changePosition(position.left, position.top);

        self.dragStarted();
    });
}

Moset.prototype.dragStarted = function() {
    let self = this;
    $(document).removeClass(this.snappedClass);

    $(document).on('mousemove.moset', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let changeX = e.clientX - self.mouseX;
        let changeY = e.clientY - self.mouseY;

        self.changePosition(self.elementX + changeX, self.elementY + changeY);
        self.mouseX = e.clientX;
        self.mouseY = e.clientY;
    });

    $(document).on('mouseup.moset', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(document).off('.moset');
        self.dragEnded();
    });
}

Moset.prototype.getBoundaries = function() {
    return {
        x: window.innerWidth,
        y: window.innerHeight,
    };
}

Moset.prototype.checkBoundaries = function() {
    let limit = this.getBoundaries();

    let elem = $(this.controlId);
    let elementHeight = elem.height();
    let elementWidth = elem.width();
    if (isNaN(elementHeight)) elementHeight = 0;
    if (isNaN(elementWidth)) elementWidth = 0;

    this.elementX = Math.max(0, Math.min(limit.x - elementWidth, this.elementX));
    this.elementY = Math.max(0, Math.min(limit.y - elementHeight, this.elementY));

    let result = false;
    let midBottom = limit.y * 0.5;
    let midRight = limit.x * 0.5;

    let elX = this.elementX;
    let elY = this.elementY;

    if (elX <= 0) {
        result = ((elY + elementHeight * 0.5) > midBottom ? "bottom" : "top")  + "-left";
    } else if (elX >= limit.x - elementWidth) {
        result = ((elY + elementHeight * 0.5) > midBottom ? "bottom" : "top") + "-right";
    } else if (elY >= limit.y - elementHeight) {
        result = "bottom-" + (elX > midRight ? "right" : "left");
    } else if (elY <= 0) {
        result = "top-" + (elX > midRight ? "right" : "left");
    }

    if (result !== false) {
        result = "meeb__admin-toolbar-" + result;
    }

    return result;
}

Moset.prototype.removeBodyClass = function (cls) {
    let body = $('body');
    if (body.hasClass(cls)) body.removeClass(cls);
}

Moset.prototype.cleanBoundariesClasses = function(except) {
    let bottomLeft = 'meeb__admin-toolbar-bottom-left';
    let bottomRight = 'meeb__admin-toolbar-bottom-right';
    let topLeft = 'meeb__admin-toolbar-top-left';
    let topRight = 'meeb__admin-toolbar-top-right';

    if (except !== bottomLeft) this.removeBodyClass(bottomLeft);
    if (except !== bottomRight) this.removeBodyClass(bottomRight);
    if (except !== topLeft) this.removeBodyClass(topLeft);
    if (except !== topRight) this.removeBodyClass(topRight);
}

Moset.prototype.changePosition = function(x, y, checkBoundaries) {
    this.elementX = x;
    this.elementY = y;

    if (typeof checkBoundaries === 'undefined') {
        checkBoundaries = true;
    }

    if (checkBoundaries) {
        let cls = this.checkBoundaries();
        if (cls !== false) {
            this.cleanBoundariesClasses(cls);
            $('body').addClass(cls);
        } else {
            this.cleanBoundariesClasses();
        }
    }

    this.savePosition();
    let target = $(this.controlId);

    target.css('top', this.elementY);
    target.css('left', this.elementX);
}

Moset.prototype.dragEnded = function() {
    let cls = this.checkBoundaries();
    if (cls !== false) {
        $('body').addClass(this.snappedClass);
        this.saveState(true);
        this.hideLinks();
    }
}

Moset.prototype.collapse = function() {
    let limit = this.getBoundaries();

    let elem = $(this.controlId);
    let elementHeight = elem.height();
    if (isNaN(elementHeight)) elementHeight = 0;

    let midBottom = limit.y * 0.5;
    let midRight = limit.x * 0.5;

    let elX = this.elementX;
    let elY = this.elementY;

    let result = ((elY + elementHeight * 0.5) > midBottom ? "bottom" : "top");
    result = result + '-' + (elX > midRight ? "right" : "left");
    result = 'meeb__admin-toolbar-' + result;

    let body = $('body');
    body.addClass(this.snappedClass);
    body.addClass(result);

    this.hideLinks();
    this.saveState(true);
}

Moset.prototype.savePosition = function() {
    if (typeof(Storage) !== "undefined") {
        // Store
        localStorage.setItem("mosetPosX", this.elementX);
        localStorage.setItem("mosetPosY", this.elementY);
    }
}

Moset.prototype.restorePosition = function() {
    if (typeof(Storage) !== "undefined") {
        let x = parseInt(localStorage.getItem('mosetPosX'));
        let y = parseInt(localStorage.getItem('mosetPosY'));

        this.changePosition(x, y, false);
        //this.dragEnded();
    }

    return false;
}

Moset.prototype.saveState = function(isCollapsed) {
    if (typeof(Storage) !== "undefined") {
        localStorage.setItem("mosetStateCollapsed", isCollapsed);
    }
}

Moset.prototype.restoreState = function() {
    if (typeof(Storage) !== "undefined") {
        let isCollapsed = localStorage.getItem("mosetStateCollapsed") === 'true';
        if (isCollapsed) {
            this.collapse();
        } else {
            let body = $('body');
            body.removeClass(this.snappedClass);
            this.cleanBoundariesClasses();

            this.openLinks();
        }

        return;
    }

    this.collapse();
}